import React, { useState } from "react"
import Layout from "../components/layout"
import { MdCall, MdLocationOn, MdEmail } from "react-icons/md"
import Header from "../components/header"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { useSpring, animated } from "react-spring"
import { useForm } from "react-hook-form"
import axios from "axios"

const Contactus = props => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm()

  const [sent, setSent] = useState(false)

  const onSubmit = async data => {
    setSent(true)
    const res = await axios.post(
      "https://mica-glimmer-jeep.glitch.me/send",
      data,
      { headers: { Accept: "application/json" } }
    )

    console.log("RES", res.data)

    setSent(false)

    if (res.data.success) {
      reset()
      alert("Your message has been sent.Thank you")
    }
  }

  console.log("Errors", errors)

  const data = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { regex: "/contactus/" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 500, jpegQuality: 100) {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    }
  `)

  console.log("CONTACT US IMAGES", data)
  const image1 = data.allFile.edges[0].node.childImageSharp.fluid

  const headingTrans = useSpring({
    config: { friction: 70 },
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const formTrans = useSpring({
    config: { friction: 50 },
    from: { opacity: 0, transform: "translateY(10rem)" },
    to: { opacity: 1, transform: "translateY(0rem)" },
    delay: 300,
  })

  const infoTrans = useSpring({
    config: { friction: 50 },
    from: { opacity: 0, transform: "translateY(10rem)" },
    to: { opacity: 1, transform: "translateY(0rem)" },
    delay: 600,
  })

  return (
    <Layout>
      <Header path={props.path} />
      <div className="contactus">
        <div className="contactus__main">
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 contactus__container1">
              <animated.h1 className="contactus__heading" style={headingTrans}>
                Let's talk
              </animated.h1>

              <h4 className="contactus__description">
                To request a quote or want to meet up for coffe, contact us
                directly or fill out the form and we will get back to you
                promtly.
              </h4>

              <animated.div style={formTrans} className="form">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  // action="https://getform.io/f/cae2f86e-b6fc-4638-9da0-767aa35ac5ea"
                  // method="POST"
                >
                  <div className="form-group">
                    <label className="form__label" htmlFor="">
                      Your Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      className="form__input"
                      {...register("name", { required: true })}
                    />
                    {errors["name"] && (
                      <span className="error">Name is required</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form__label" htmlFor="">
                      Your Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="form__input"
                      {...register("email", { required: true })}
                    />
                    {errors["email"] && (
                      <span className="error">Email is required</span>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="form__label" htmlFor="">
                      Your Message
                    </label>
                    <textarea
                      type="text"
                      rows="6"
                      name="message"
                      className="form__textarea"
                      {...register("message", { required: true })}
                    />
                    {errors["message"] && (
                      <span className="error">Message is required</span>
                    )}
                  </div>
                  <button
                    type="submit"
                    disabled={sent}
                    className="service__btn working__btn small_btn mt-5"
                  >
                    Submit message
                  </button>
                </form>
              </animated.div>

              {/* <div className="form"></div> */}
            </div>
            <div className="col-12 col-md-6 contactus__container2">
              <div className="contactus__img d-none d-md-block">
                <Img fluid={image1} alt={image1.originalName} />
              </div>
              <hr className="d-sm-none horizontal" />
              <animated.div style={infoTrans} className="form__info">
                <div className="form__location">
                  <MdLocationOn className="form__icon" />{" "}
                  <h3>
                    A-1510, Maxblis Grand kingston, Sector-75, Noida - 201301
                  </h3>
                </div>
                <div className="form__call">
                  <MdCall className="form__icon" /> <h3>+91 (8574271877)</h3>
                </div>
                <div className="form__email">
                  <MdEmail className="form__icon" />{" "}
                  <h3>info@contriontechnology.com</h3>
                </div>
              </animated.div>

              <div className="social"></div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contactus
